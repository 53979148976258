
import { Component, Vue } from "vue-property-decorator";
import NotAuthHeader from "@/components/NotAuthHeader.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import AuthModule from "@/store/auth";
@Component({
  name: "ResetPassword",
  components: {
    NotAuthHeader,
    ManagementFooter,
  },
})
export default class ResetPassword extends Vue {
  private get beforeChangePassRoot() {
    return AuthModule.beforeChangePassRoot;
  }
  private back() {
    const routeName = this.beforeChangePassRoot ?? "";
    this.$router.push({ name: routeName });
    AuthModule.changePassRoot("");
  }
}
